import React from 'react'

import BarChartTemplate from '../../Components/BarChart.Template'
import { useHistory, useParams } from 'react-router-dom'

import { getProduct360Charts } from 'Services/Analytics/product-analytics'
import BarLineChartTemplate from 'Pages/Analytics/SaleAnalytics/Components/BarLineChart.Template'
import StackedBarLine from 'Pages/Analytics/SaleAnalytics/Components/StackedBarLine.Template'

const ChartRow2 = () => {
  const { id } = useParams()
  return (
    <>
      <div className="col-span-1 p-4 bg-white shadow-div_b">
        <div className="mb-4 font-semibold">Products sale during day of weeks</div>
        <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ minHeight: 300 }}>
          <StackedBarLine
            chartCode="product_sale_during_day_of_weeks"
            handleDataFunction={getProduct360Charts}
          />
        </div>
      </div>
      <div className="col-span-2 p-4 bg-white shadow-div_b">
        <div className="mb-4 font-semibold">Products sale during time of day</div>
        <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ minHeight: 300 }}>
          <StackedBarLine
            chartCode="product_sale_during_time_of_days"
            handleDataFunction={getProduct360Charts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow2
