import React from 'react'
import { CUSTOMER_DIMENSION, CUSTOMER_METRICS } from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'
import ProductV2PivotTable from 'Components/Charts/ProductV2PivotTable'
import { DataSourceContext } from 'Pages/Analytics/Products/Overview/index'
const ChartRow_10 = () => {
  const dataSource = React.useContext(DataSourceContext)
  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold">Customer Insight - Trend view</div>
        {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
      </div>
      <ProductV2PivotTable
        analytic_type="customer_insight"
        source={dataSource}
      ></ProductV2PivotTable>
    </div>
  )
}

export default ChartRow_10
