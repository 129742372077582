import React from 'react'

import { getCampaignOverviewCharts } from 'Services/Analytics/campaign-analytics'
import MultiDoughnutChartTemplate from '../../../Components/MultiDoughnutChart.Template'

const CustomerInsights = () => {
  return (
    <div className="w-full h-full grid grid-cols-3 grid-rows-2">
      <div style={{ height: 350 }} className="overflow-scroll shadow-div_r px-4">
        <div className="font-semibold mb-4">New vs Return</div>
        <div style={{ height: 200 }}>
          <MultiDoughnutChartTemplate
            className="customer-insight"
            chartCode="new_vs_return"
            handleDataFunction={getCampaignOverviewCharts}
          />
        </div>
      </div>
      <div style={{ height: 350 }} className="overflow-scroll shadow-div_r px-4">
        <div className="font-semibold mb-4">Customer Life Cycle (CLC)</div>
        <div style={{ height: 200 }}>
          <MultiDoughnutChartTemplate
            className="customer-insight"
            chartCode="customer_life_cycle"
            handleDataFunction={getCampaignOverviewCharts}
          />
        </div>
      </div>
      <div style={{ height: 350 }} className="h-full shadow-div_b px-4">
        <div className="font-semibold mb-4">Customer Lifetime Value (LTV)</div>
        <div style={{ height: 200 }}>
          <MultiDoughnutChartTemplate
            className="customer-insight"
            chartCode="customer_lifetime_value"
            handleDataFunction={getCampaignOverviewCharts}
          />
        </div>
      </div>
      <div style={{ height: 350 }} className="overflow-scroll shadow-div_tr px-4">
        <div className="font-semibold mb-4 pt-4">Membership</div>
        <div style={{ height: 200 }}>
          <MultiDoughnutChartTemplate
            className="customer-insight"
            chartCode="membership"
            handleDataFunction={getCampaignOverviewCharts}
          />
        </div>
      </div>
      <div style={{ height: 350 }} className="overflow-scroll shadow-div_tr px-4">
        <div className="font-semibold mb-4 pt-4">Cluster</div>
        <div style={{ height: 200 }}>
          <MultiDoughnutChartTemplate
            className="customer-insight"
            chartCode="cluster"
            handleDataFunction={getCampaignOverviewCharts}
          />
        </div>
      </div>
      {/* <div style={{ height: 300 }} className="overflow-scroll shadow-div_t px-4">
        <div className="font-semibold mb-4 pt-4">Channel Insight</div>
        <div style={{height: 200}}>
          <MultiDoughnutChartTemplate
            className="customer-insight"
            chartCode="channel_insight"
            handleDataFunction={getCampaignOverviewCharts}
          />
        </div>
      </div> */}
    </div>
  )
}

export default CustomerInsights
