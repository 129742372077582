import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BarChartTemplate from '../../Components/BarChart.Template'
import MultiDoughnutChartTemplate from '../../Components/MultiDoughnutChart.Template'

import {
  getCustomerOverviewCharts,
  getCustomer360Charts,
} from 'Services/Analytics/customer-analytics'
import { getProduct360Test, getProduct360Charts } from 'Services/Analytics/product-analytics'
import DoughnutChartTemplate from '../../Components/DoughnutChart.Template'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'

const ChartRow3 = () => {
  const { id } = useParams()
  return (
    <>
      <div className="p-4 col-span-3 shadow-div_rb">
        <div className="flex justify-between items-center">
          <div className="font-semibold">Customer Insight</div>
          <div className="flex text-brand_primary ">
            <div
              className="mr-4 cursor-pointer"
              onClick={() => window.open('/analytics/customers?tab=customer_listing', '_self')}
            >
              View Customer List
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Customer Lifetime Value (LTV)</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="ltv"
            handleDataFunction={getProduct360Charts}
            barcode={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Cluster</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="cluster"
            handleDataFunction={getProduct360Charts}
            barcode={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Membership</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="membership"
            handleDataFunction={getProduct360Charts}
            barcode={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Customer Life Cycle (CLC)</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="clc"
            handleDataFunction={getProduct360Charts}
            barcode={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Purchased Category </div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="purchased_category"
            handleDataFunction={getProduct360Charts}
            barcode={id}
            unit={'categories'}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Top Category</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 260 }}>
          <BarChartTemplate
            barcode={id}
            chartCode="top_category"
            hideLegends
            isHorizontal
            max={'auto'}
            isPercentage={false}
            handleDataFunction={getProduct360Charts}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">{`Consuming Frequency & Revenue`}</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="consumer_frequency_and_revenue"
            handleDataFunction={getProduct360Charts}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">AOV Range</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="aov_range"
            handleDataFunction={getProduct360Charts}
            unit={'customer'}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold" style={{ color: 'rgba(0,0,0,0.5)' }}>
            Product Mission
          </div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="product_mission"
            handleDataFunction={getProduct360Charts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow3
