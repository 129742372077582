import React from 'react'
import { Select, Skeleton, Tabs, Tooltip } from 'antd'
import CatePivotTemplate from 'Pages/Analytics/Products/Components/CatePivotTemplate'

const ChartRow_8 = () => {
  return (
    <div>
      <div className=" col-span-1 CatePivot">
        <Tooltip
          title="This static table won't be impacted by the selected time range and frequency"
          placement="top"
          overlayStyle={{ fontSize: '12px', backgroundColor: '#000000', width: '188px' }}
        >
          <div className="font-semibold text-lg " style={{ width: 'fit-content' }}>
            Inventory Insight
          </div>
        </Tooltip>
      </div>
      <div>
        <CatePivotTemplate
          analytic_type="inventory_insight"
          is360Overview={true}
        ></CatePivotTemplate>
      </div>
    </div>
  )
}

export default ChartRow_8
