import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { getProduct360FunnelData } from 'Services/Analytics/product-analytics'

import Info from './Info'
import KeyMetricsTemplate from '../../Components/KeyMetrics.Template'

import ChartRow1 from './ChartRow_1'
import ChartRow2 from './ChartRow_2'
import ListRow from './ListRow'
import MixRow from './MixRow'
import ChartRow3 from './ChartRow3'
import { getCustomerOverviewKeyMetrics } from 'Services/Analytics/customer-analytics'
import KeyMetrics from './KeyMetrics'
import FunnelChart from 'Components/Charts/FunnelChart'
import FunnelChartTemplate from 'Pages/Analytics/Components/FunnelChartTemplate'
import ChartRow_1 from './ChartRow1'
import ChartRow_4 from './ChartRow_4'
import { Tabs } from 'antd'
import ChartRow_5 from './ChartRow_5'
import ProductOverviewFunnel from 'Pages/Analytics/Components/ProductOverviewFunnelTemplate'
import {
  getProduct360FunnelDatas,
  getProductOverviewFunnelData,
} from 'Services/Analytics/saleChannel-analytics'
import ChartRow_6 from './ChartRow_6'
import ChartRow_7 from './ChartRow_7'
import ChartRow_8 from './ChartRow_8'
import Latency from 'Pages/Analytics/Components/Latency'
import './index.scss'
import FiltersHeader from 'Pages/Analytics/Components/FiltersHeader'
// import FunnelChart from 'Components/Charts/FunnelChart'

const Product360Body = () => {
  const { id } = useParams()
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })
  const { ref: ref10, inView: ref10InView } = useInView({ triggerOnce: true })
  const { ref: ref11, inView: ref11InView } = useInView({ triggerOnce: true })
  const { ref: ref12, inView: ref12InView } = useInView({ triggerOnce: true })
  const { ref: ref13, inView: ref13InView } = useInView({ triggerOnce: true })
  const { ref: ref14, inView: ref14InView } = useInView({ triggerOnce: true })
  const { ref: ref15, inView: ref15InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>
  return (
    <div className="Product360-body-wrapper">
      <FiltersHeader hideDimensions={true} />
      <div className="mt-4 mb-4">
        <Latency />
      </div>
      <div className="mb-4 bg-white">
        <div style={{ minHeight: 300 }} ref={ref2} className="p-4 mt-4 bg-white">
          {ref2InView && <Info />}
        </div>
      </div>
      <div
        style={{ minHeight: 200 }}
        ref={ref1}
        className="p-4 bg-white customers-analytics-overview-metrics"
      >
        <div className="mb-4 text-lg font-semibold">Metrics</div>
        {ref1InView && (
          <Tabs>
            <Tabs.TabPane tab="Revenue" key="1" className="Product360-Revenue-Metrics">
              <KeyMetrics analytic_type="revenue"></KeyMetrics>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Customer" key="2" className="Product360-Customer-Metrics">
              <KeyMetrics analytic_type="customer"></KeyMetrics>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Sales Channel" key="3" className="Product360-SaleChannel-Metrics">
              <KeyMetrics analytic_type="sale_channel"></KeyMetrics>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Inventory" key="4" className="Product360-Inventory-Metrics">
              <KeyMetrics analytic_type="inventory"></KeyMetrics>
            </Tabs.TabPane>
          </Tabs>
        )}
      </div>
      <div ref={ref3} className="mt-4 ">
        {ref3InView && <ChartRow_1 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref4} className="p-4 mt-4 bg-white">
        {ref4InView && <ChartRow_4 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref5} className="grid grid-cols-3 gap-4 mt-4">
        {ref5InView && <ChartRow1 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref6} className="grid grid-cols-3 gap-4 mt-4">
        {ref6InView && <ChartRow2 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref7} className="p-4 mt-4 bg-white">
        {ref7InView && <ChartRow_5 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref8} className="grid grid-cols-3 gap-4 mt-4">
        {ref8InView && <ListRow />}
      </div>
      <div style={{ minHeight: 600 }} ref={ref9} className="grid grid-cols-3 mt-4 bg-white">
        {ref9InView && <ChartRow3 />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref10} className="grid grid-cols-3 mt-4 bg-white">
        {ref10InView && <MixRow />}
      </div>
      <div style={{ minHeight: 300 }} ref={ref11} className="p-4 mt-4 bg-white">
        {ref11InView && <ChartRow_7 />}
      </div>
      <div style={{ minHeight: 600 }} ref={ref12} className="p-4 bg-white">
        {ref12InView && <ChartRow_6 />}
      </div>

      <div
        style={{ minHeight: 300, position: 'relative' }}
        ref={ref13}
        className="p-4 mt-4 bg-white"
      >
        {ref13InView && <ChartRow_8 />}
      </div>
      {/* <div style={{ minHeight: 300 }} ref={ref7} className="mt-4 bg-white">
        {ref7InView && (
          <div className="col-span-3 p-4 bg-white shadow-div_b">
            <div className="mb-4 font-semibold">Audience Funnel in Online Order</div>
            <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ minHeight: 300 }}>
              <FunnelChartTemplate
                barcode={id}
                handleDataFunction={getProduct360FunnelData}
              ></FunnelChartTemplate>
            </div>
          </div>
        )}
      </div> */}
      {/* <div style={{ minHeight: 300 }} ref={ref14} className="mt-4 bg-white">
        {ref14InView && (
          <div className="col-span-3 p-4 bg-white shadow-div_b">
            <div className="mb-4 font-semibold">Audience Funnel on Digital Channel (Web/App)</div>
            <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ minHeight: 300 }}>
              <ProductOverviewFunnel
                firstColumn="Total Deliver = Impression"
                analytic_type="audience_funnel_on_digital_channel"
                handleDataFunction={getProduct360FunnelDatas}
              ></ProductOverviewFunnel>
            </div>
          </div>
        )}
      </div> */}
      {/* <div style={{ minHeight: 300 }} ref={ref15} className="bg-white">
        {ref15InView && (
          <div className="col-span-3 p-4 bg-white shadow-div_b">
            <div className="mb-4 font-semibold">Audience Funnel on Advertising</div>
            <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ minHeight: 300 }}>
              <ProductOverviewFunnel
                firstColumn="Engage (SMS, Notification)"
                analytic_type="audience_funnel_on_digital_channel"
                handleDataFunction={getProduct360FunnelDatas}
              ></ProductOverviewFunnel>
            </div>
          </div>
        )}
      </div> */}
    </div>
  )
}

export default Product360Body
