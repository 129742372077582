import React, { useCallback, useEffect, useState } from 'react'
import { message, Select, Skeleton, Tree } from 'antd'
import './index.scss'
import ProductV2PivotTable from 'Components/Charts/ProductV2PivotTable'
import { getProductCategoryList } from 'Services/Analytics/product-analytics'
import { DataSourceContext } from 'Pages/Analytics/Products/Overview/index'

const CatePivotTemplate = ({
  is360Overview,
  page_size,
  page,
  analytic_type,
  order_by,
  category_id,
  is360,
  setTotalPage,
  setCurrentPage,
}: {
  is360Overview?: boolean
  page_size?: any
  page?: any
  analytic_type?: string
  order_by?: string
  category_id?: string
  is360?: boolean
  setTotalPage?: any
  setCurrentPage?: any
}) => {
  const [categoryList, setCategoryList] = useState()
  const [cateId, setCateId] = useState<any>(category_id)
  const [toggle, setToggle] = useState<boolean>(true)
  const dataSource = React.useContext(DataSourceContext)

  const handleCateData = async () => {
    try {
      const resp = await getProductCategoryList({
        analytic_type: 'categories_x_products',
        source: dataSource,
      })
      setCategoryList(resp.data.children)
    } catch (err: any) {
      message.error('Something went wrong')
    }
  }

  useEffect(() => {
    handleCateData()
  }, [dataSource])
  useEffect(() => {
    setCateId(category_id)
  }, [category_id])

  return (
    <div className="bg-white CatePivot-Trendview">
      <div>
        <div className="flex mr-1 rounded ">
          <div style={{ backgroundColor: '#f5f5f5' }}>
            <div
              className="bg-white cursor-pointer justify-center items-center"
              style={{
                display: toggle ? 'none' : 'flex',
                width: 24,
                height: 40,
                fontSize: 18,
                transform: 'translateX(-16px)',
                borderRadius: '0 4px 4px 0',
                top: 146,
                position: 'absolute',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
                zIndex: 1,
              }}
              onClick={() => setToggle(true)}
            >
              {`>`}
            </div>
          </div>
          <div
            className="relative bg-white left-corner-radius"
            style={{ width: 304, display: toggle ? '' : 'none' }}
          >
            <div
              style={{ height: 88, background: 'var(--table_header)', fontWeight: 600 }}
              className="flex p-4 text-sm border-b top-left-corner-radius text-secondary_text justify-between items-center"
            >
              <div className="flex" style={{ color: '#000000' }}>
                Categories
                {/* <IconSort className="ml-2 cursor-pointer"></IconSort> */}
              </div>
              <div
                style={{ cursor: 'pointer', fontSize: 18 }}
                onClick={() => setToggle(false)}
              >{`<`}</div>
            </div>

            <Tree
              treeData={categoryList}
              onSelect={(checkedKeys) => {
                setCateId(checkedKeys[0])
                setCurrentPage(1)
              }}
            />
          </div>
          <div className="flex items-center justify-between mb-4">
            {/* <div className="flex text-brand_primary">
          <div className="mr-4">
            <RefreshIcon />
          </div>
          <div>
            <DownIcon />
          </div>
        </div> */}
          </div>
          <ProductV2PivotTable
            analytic_type={analytic_type}
            cate_id={cateId}
            order_by={order_by}
            currentPage={page}
            pageSize={page_size}
            is360={is360}
            is360Overview={is360Overview}
            setTotalPage={setTotalPage}
            source={dataSource}
          />
        </div>
      </div>
    </div>
  )
}

export default CatePivotTemplate
