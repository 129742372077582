import React from 'react'
import ProductV2PivotTable from 'Components/Charts/ProductV2PivotTable'

const ChartRow_6 = () => {
  return (
    <div className=" col-span-1 CatePivot">
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold">{`Supplier Performance Trendview*`}</div>
        {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
      </div>
      <ProductV2PivotTable
        analytic_type="supplier_performance"
        is360
        isTrendview
      ></ProductV2PivotTable>
    </div>
  )
}

export default ChartRow_6
