import React, { useCallback, useEffect, useState } from 'react'
import { Input, Select, Skeleton, Tabs } from 'antd'
import CateTable from 'Components/Charts/CateTable/CateTable'
import ProductV2PivotTable from 'Components/Charts/ProductV2PivotTable'

const { TabPane } = Tabs
const { Option } = Select
const ChartRow_4 = () => {
  return (
    <div className=" col-span-1 CatePivot">
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold text-lg">Placement and Inventory</div>
        {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
      </div>

      <Tabs defaultActiveKey="2">
        {/* <TabPane tab="Online Sale Channel" key="1">
          <ProductV2PivotTable
            analytic_type="placement_by_online_channel"
            is360
          ></ProductV2PivotTable>
        </TabPane> */}
        <TabPane tab="Physical Store" key="2">
          <ProductV2PivotTable
            analytic_type="placement_by_physical_store"
            is360
          ></ProductV2PivotTable>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ChartRow_4
