import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import StatusBadge from 'Components/Charts/CateTable/Components/StatusBadge'
import kFormatter, { thousandsSeparator } from 'Utils/kFormatter'
import { Popover } from 'antd'

interface DataType {
  key: number
  supplier_name?: string
  supplier_performance: string
  category_manager?: string
  supplier_sales_person?: string
  cost_price?: number
  avg_rev_per_day?: number
  payable?: number
  purchased_value?: number
  inventory_value?: number
  inventory_quantity?: number
  inventory_clearance?: number
  back_margin?: number
  storage?: string[]
  total: number
  status_star: number
  status_yellow: number
  status_red: number
  status_green: number
  deactive: number
  clear: number
}
export const columns: ColumnsType<DataType> = [
  {
    title: 'Supplier Name',
    dataIndex: 'supplier',
    key: 'supplier',
    width: 254,
  },
  {
    title: 'Supplier Performance',
    dataIndex: 'supplier_status',
    key: 'supplier_status',
    width: 160,
    // fixed: 'left',
    render: (text: string) => text && <StatusBadge status={text}></StatusBadge>,
  },

  // {
  //   title: 'Category Manager',
  //   dataIndex: 'category_manager',
  //   key: 'category_manager',
  //   width: 176,
  // },
  {
    title: 'Supplier Sales Person',
    dataIndex: 'supplier_sale_person',
    key: 'supplier_sale_person',
    width: 176,
  },
  {
    title: 'Cost Price',
    dataIndex: 'cogs',
    key: 'cogs',
    width: 132,
    render: (value: number) => value && <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: 'Avg Rev per Day (Product)',
    dataIndex: 'avg_revenue',
    key: 'avg_revenue',
    width: 154,
    render: (value: number) => value && <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Payable',
    dataIndex: 'payable',
    key: 'payable',
    width: 132,
    render: (value: number) => value && <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Purchased Value',
    dataIndex: 'purchased_value',
    key: 'purchased_value',
    width: 152,
    render: (value: number) => value && <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Inventory Value',
    dataIndex: 'inventory_value',
    key: 'inventory_value',
    width: 152,
    render: (value: number) => value && <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Inventory Quantity',
    dataIndex: 'inventory_quantity',
    key: 'inventory_quantity',
    width: 152,
    render: (value: number) => value && <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Inventory Day',
    dataIndex: 'inventory_clearance',
    key: 'inventory_clearance',
    width: 132,
    render: (value: number) => value && <div>{value?.toFixed(0)}</div>,
  },
  {
    title: 'Back Margin',
    dataIndex: 'back_margin',
    key: 'back_margin',
    width: 132,
    render: (value: number) => value && <div>{value?.toFixed(0)}%</div>,
  },
  {
    title: 'Storage',
    dataIndex: 'storage',
    key: 'storage',
    width: 320,
    render: (value: string[]) =>
      value.length && (
        <div>
          {value?.length <= 1 ? (
            value?.map((item, index) => {
              return (
                <div>
                  <span>
                    <span>{item}</span>
                  </span>
                </div>
              )
            })
          ) : (
            <div>
              <span>
                <span>{value[0]}</span>
              </span>
              <Popover
                content={
                  <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                    {value?.map((item, index) => {
                      if (index >= 1) return <div>{item}</div>
                    })}
                  </span>
                }
              >
                <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${
                  value?.length - 1
                } other stores`}</span>
              </Popover>
            </div>
          )}
        </div>
      ),
  },
  {
    title: 'No. of SKU by Product Performance',
    dataIndex: 'no_of_sku_by_product_performance',
    key: 'no_of_sku_by_product_performance',
    children: [
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 112,
        sorter: {
          compare: (a, b) => a.total - b.total,
          multiple: 2,
        },
        render: (value: number) => value && <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Star',
        dataIndex: 'status_star',
        key: 'status_star',
        width: 112,
        sorter: {
          compare: (a, b) => a.status_star - b.status_star,
          multiple: 4,
        },
        render: (value: number) => value && <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Yellow',
        dataIndex: 'status_yellow',
        key: 'status_yellow',
        width: 112,
        sorter: {
          compare: (a, b) => a.status_yellow - b.status_yellow,
          multiple: 5,
        },
        render: (value: number) => value && <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Red',
        dataIndex: 'status_red',
        key: 'status_red',
        width: 112,
        sorter: {
          compare: (a, b) => a.status_red - b.status_red,
          multiple: 6,
        },
        render: (value: number) => value && <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Green',
        dataIndex: 'status_green',
        key: 'status_green',
        width: 112,
        sorter: {
          compare: (a, b) => a.status_green - b.status_green,
          multiple: 7,
        },
        render: (value: number) => value && <div>{thousandsSeparator(value)}</div>,
      },
      // {
      //   title: 'Deactive',
      //   dataIndex: 'deactive',
      //   key: 'deactive',
      //   width: 112,
      //   sorter: {
      //     compare: (a, b) => a.deactive - b.deactive,
      //     multiple: 8,
      //   },
      // },
      // {
      //   title: 'Clear',
      //   dataIndex: 'clear',
      //   key: 'clear',
      //   width: 112,
      //   sorter: {
      //     compare: (a, b) => a.clear - b.clear,
      //     multiple: 9,
      //   },
      // },
    ],
  },
]
