import React from 'react'
import ChartLineTemplate from 'Pages/Analytics/SaleAnalytics/Components/ChartLine'
import { getProduct360Charts } from 'Services/Analytics/saleChannel-analytics'

const ChartRow_1 = () => {
  return (
    <div>
      <div className="p-4 bg-white col-span-3">
        <div className="pb-4 text-base font-semibold text-primary_text text-body">
          Pricing Comparison (MTD)
        </div>
        <ChartLineTemplate
          chartCode="pricing_comparision"
          handleDataFunction={getProduct360Charts}
        />
      </div>
    </div>
  )
}

export default ChartRow_1
