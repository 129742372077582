import React, { useEffect, useState, useRef } from 'react'
import { Modal } from 'antd'

const InfoTags = ({ data }: { data: any }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showSeeAll, setShowSeeAll] = useState(false)
  const segmentRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const segmentHeight: any = segmentRef?.current?.clientHeight
    if (segmentHeight > 38) {
      setShowSeeAll(true)
    } else {
      setShowSeeAll(false)
    }
  })

  return (
    <div>
      <div className="flex justify-between ">
        <div className="pt-4 flex gap-4" ref={segmentRef}>
          {/* <div className="font-semibold">Segments</div> */}
          <div
            className="flex gap-2 flex-wrap overflow-hidden"
            style={{ height: showSeeAll ? 30 : 'unset' }}
          >
            {data?.map((segment, index) => (
              <span key={index} className="font-semibold bg-gray_2 text-gray_7 rounded-sm px-2">
                {segment}
              </span>
            ))}
          </div>
        </div>
        <div
          style={{ width: '82px' }}
          className={`${showSeeAll ? 'mr-2 pt-4 text-right text-blue_6 cursor-pointer' : 'hidden'}`}
          onClick={() => {
            setIsModalVisible(true)
          }}
        >
          See all
        </div>
      </div>
      <Modal
        // title="Segments"
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        width={572}
        wrapClassName="Customer360Header-segment-modal"
      >
        <div className="flex gap-2 flex-wrap">
          {data?.map((segment, index) => (
            <span key={index} className="font-semibold bg-gray_2 text-gray_7 rounded-sm px-2">
              {segment}
            </span>
          ))}
        </div>
      </Modal>
    </div>
  )
}

export default InfoTags
