import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Empty, Skeleton, Table } from 'antd'
import axios from 'axios'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { getProduct360SupplierListing } from 'Services/Analytics/product-analytics'
import { columns } from './Constants'

const ChartRow_7 = () => {
  const history = useHistory()
  const [error, setError] = useState('')
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { filters: defaultFilters } = useFilters()
  const queryObject = queryString.parse(history.location.search)
  const { id } = useParams()

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        // setChart(CHART)
        const resp = await getProduct360SupplierListing({
          barcode: id,
          type: 'supplier_listing',
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          // cancelToken: cancelTokenSource?.token,
        })
        // console.log('resp', resp)
        setData(resp?.data)
      } catch (err: any) {
        console.log(err)
        if (err.message === 'cancelled') return
        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'No data available'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()
    return () => {
      // setData()
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [queryObject.from_date, queryObject.to_date, queryObject.frequency, queryObject.dimensions])
  const renderChart = useMemo(() => {
    if (!data) return <Empty />

    return (
      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="middle"
        scroll={{
          x: 'calc(700px + 50%)',
          y: 240,
        }}
        pagination={false}
      />
    )
  }, [data])

  if (error) return <Alert message={error} type="error" />

  return (
    <div className=" col-span-1 ProductPerformanceAndSupplierStatus">
      <div>
        <div className="flex justify-between items-center">
          <div className="font-semibold text-lg mb-4">Supplier Insight</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">Refresh all</div>
            <div>Download all</div>
          </div> */}
        </div>
      </div>
      <div className="mb-4 flex flex-col items-center">
        <div className="font-semibold self-baseline mb-4">Supplier Listing</div>
        {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        <Skeleton loading={isLoading} active>
          {data && renderChart}
        </Skeleton>
      </div>
    </div>
  )
}

export default ChartRow_7
