import { Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { systemColor } from 'DesignSystem/Colors'
import { capitalizeFirstLetter } from 'Utils'
import moment from 'moment'
import axios from 'Utils/axios'
import queryString from 'query-string'
import _ from 'lodash'

import {
  APP_PUSH_METRIC,
  COLUMNS,
  EMAILCOLUMNS,
  FACEBOOK_ADS,
  SMSCOLUMNS,
  WEBSITECOLUMNS,
} from './constants'

export interface ICampaignMetrics {
  campaign_id?: number
  analytic_key_metrics?: AnalyticKeyMetrics
}

export interface AnalyticKeyMetrics {
  impression?: number
  revenue?: number
  cost?: number
  open?: number
  click?: number
  cr?: number
  cpc?: number
}

const computedColumns = (channel) => {
  if (channel.length > 3) return COLUMNS
  else {
    const typeValue = channel[1]
    switch (typeValue) {
      case 'website':
        return WEBSITECOLUMNS
      case 'sms':
        return SMSCOLUMNS
      case 'email':
        return EMAILCOLUMNS
      case 'app_push':
        return APP_PUSH_METRIC
      case 'facebook':
        return FACEBOOK_ADS
      default:
        return COLUMNS
    }
  }
}

export default function useColumns({ channel }) {
  const [data, setData] = useState([])
  const [campaignIds, setCampaignIds] = useState<number[]>([])
  const [columns, setColumns] = useState<unknown[]>(computedColumns(channel))
  const [loading, setLoading] = useState(true)

  const fetchMetrics = async (campaignIds: number[]) => {
    try {
      setLoading(true)
      const resp = await axios.get<ICampaignMetrics[]>('/campaigns/metrics', {
        params: {
          campaign_ids: campaignIds,
        },
        paramsSerializer: (p) => {
          return queryString.stringify(p, { arrayFormat: 'comma' })
        },
      })

      const _columns = columns.map((column: any) => {
        return {
          ...(column as object),
          ...(!column?.mapping
            ? undefined
            : {
                render: (value, data) => {
                  const metric = resp.data?.find((item) => item.campaign_id === value)
                    ?.analytic_key_metrics?.[column?.code]
                  return metric !== undefined ? Math.round(metric).toLocaleString() : 'N/A'
                },
              }),
        }
      })
      setColumns(_columns)
      setLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    if (campaignIds?.length) fetchMetrics(campaignIds)
  }, [campaignIds])

  useEffect(() => {
    const _campaignIds = data?.map((item: any) => item.id) || []
    if (!_.isEqual(_campaignIds, campaignIds)) {
      setCampaignIds(_campaignIds)
    }
  }, [data])

  return {
    loading,
    columns,
    setData,
  }
}
