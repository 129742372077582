import React from 'react'
import MultiDoughnutChartTemplate from '../../Components/MultiDoughnutChart.Template'
import {
  getCustomerOverviewCharts,
  getCustomer360Charts,
} from 'Services/Analytics/customer-analytics'
import { getProduct360Test, getProduct360Charts } from 'Services/Analytics/product-analytics'
import DoughnutChartTemplate from '../../Components/DoughnutChart.Template'
import { useHistory, useParams } from 'react-router-dom'

import {
  CUSTOMER_DIMENSION,
  CUSTOMER_METRICS,
  PRODUCT_PIVOT_METRICS,
} from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../Components/PivotTable.Template'
import { CustomerPivotTypes, ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'

const MixRow = () => {
  const { id } = useParams()
  return (
    <>
      <div className="p-4 col-span-3 shadow-div_rb">
        <div className="flex justify-between items-center">
          <div className="font-semibold">Channel Insight</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">Refresh all</div>
            <div>Download all</div>
          </div> */}
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Channel Insight</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="channel_insight"
            handleDataFunction={getProduct360Charts}
            barcode={id}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Product Quantity by Channel </div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="product_quantity_by_channel_insight"
            handleDataFunction={getProduct360Charts}
            barcode={id}
            unit={'products'}
          />
        </div>
      </div>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Gross Profit by Channel</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden doughnutChart">
          <DoughnutChartTemplate
            chartCode="profit_by_channel_insight"
            handleDataFunction={getProduct360Charts}
            barcode={id}
            unit={'profit'}
          />
        </div>
      </div>
    </>
  )
}

export default MixRow
