import React, { useEffect, useMemo, useState } from 'react'
import './index.scss'
import { Alert, Card, Divider, Empty, Popover, Skeleton } from 'antd'
import ProductTag from './Tag'
import Product from 'Assets/images/product_img.png'
import { ProductInfosCard } from 'Product360Types'
import { getProduct360DetailInfos } from 'Services/Analytics/product-analytics'
import { productColor } from 'DesignSystem/Colors'
import kFormatter, { thousandsSeparator } from 'Utils/kFormatter'
import InfoTags from './Info.Tags'
import StatusBadge from 'Components/Charts/CateTable/Components/StatusBadge'
import { useHistory, useParams } from 'react-router-dom'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import queryString from 'query-string'

const Product360Info = () => {
  const { id } = useParams()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<any>()
  const history = useHistory()
  const { filters: defaultFilters } = useFilters()
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    const handleData = async () => {
      try {
        setError('')
        const resp = await getProduct360DetailInfos({
          id: id,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          // cancelToken: cancelTokenSource.token,
        })
        // console.log('Product360 info resp: ', resp)
        setData(resp?.data)
      } catch (err: any) {
        setError(err?.response?.data?.detail || 'Something went wrong')
      }
      setIsLoading(false)
    }
    handleData()
  }, [id])
  //console.log('Product360 info data: ', data)
  const renderCard = useMemo(() => {
    if (!data) return <Empty />
    return (
      <Card style={{ minHeight: 160 }} className="Product360-body-info">
        <div className="Product360-Info-Left">
          <div className="Product360-Info-Left-Above">
            <img src={data?.image} style={{ width: 112, height: 112 }}></img>
            <div className="flex flex-col Product360-Info-Left-Above-Detail ml-4">
              <div className="Product360-Info-ProductName font-semibold text-xl mb-2">
                {data?.product_name}
              </div>
              <div className="mb-2">
                <div className="flex flex-row">
                  <div>SKU:</div>
                  <div className="text-light ml-1">{data?.sku}</div>
                </div>
                <div className="flex flex-row">
                  <div>Supplier:</div>
                  <div className="text-light ml-1">{data?.supplier}</div>
                </div>
                <div className="flex flex-row">
                  <div>Category Manager:</div>
                  <div className="text-light ml-1">{data?.category_manager}</div>
                </div>
              </div>
              <div className="flex flex-row Product360-label">
                {data?.labels?.map((item, index) => {
                  return (
                    <div
                      style={{
                        backgroundColor: '#E6F7FF',
                        color: '#1890FF',
                        padding: '0px 8px',
                        marginLeft: index == 0 ? 0 : 4,
                        fontWeight: 600,
                      }}
                      className="Product360-label-item rounded "
                    >
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="Product360-Info-Left-Tags flex">
            <InfoTags data={data?.tags}></InfoTags>
          </div>
          <Divider></Divider>
          <div className="Product360-Info-Left-Below flex flex-row justify-between">
            <div className="Product360-Info-Left-Below-TextArea">
              <div className="mb-2 font-semibold">Basic Info</div>
              <div>
                <div className="flex flex-row">
                  <div>Selling price:</div>
                  <div className="text-light ml-1">
                    {thousandsSeparator(parseInt(data?.selling_price?.toFixed(0)))} đ
                  </div>
                </div>
                <div className="flex flex-row">
                  <div>Unit Price:</div>
                  <div className="text-light ml-1">
                    {thousandsSeparator(parseInt(data?.unit_price?.toFixed(0)))} đ
                  </div>
                </div>
                <div className="flex flex-row">
                  <div>Shelf life:</div>
                  <div className="text-light ml-1">{data?.shelf_life}</div>
                </div>
                <div className="flex flex-row">
                  <div>Occasion:</div>
                  <div className="text-light ml-1">{data?.occasion}</div>
                </div>
                <div className="flex flex-row">
                  <div>No. of Product Complaint:</div>
                  <div className="text-light ml-1">{data?.no_of_product_complaint}</div>
                </div>
              </div>
            </div>
            <div className="Product360-Info-Left-Below-TextArea">
              <div className="mb-2 font-semibold">Specification</div>
              <div>
                <div className="flex flex-row">
                  <div>Brand:</div>
                  <div className="text-light ml-1">{data?.brand}</div>
                </div>
                <div className="flex flex-row">
                  <div>Storage Condition:</div>
                  <div className="text-light ml-1">{data?.storage_condition}</div>
                </div>
                <div className="flex flex-row">
                  <div>Preparation:</div>
                  <div className="text-light ml-1">{data?.prepareration}</div>
                </div>
                <div className="flex flex-row">
                  <div>Required temperature:</div>
                  <div className="text-light ml-1">{data?.temperature}</div>
                </div>
                <div className="flex flex-row">
                  <div>MOQ:</div>
                  <div className="text-light ml-1">{data?.moq}</div>
                </div>
                <div className="flex flex-row">
                  <div>Delivery Last Mile:</div>
                  <div className="text-light ml-1">{data?.delivery_last_mine}</div>
                </div>
              </div>
            </div>
            <div className="Product360-Info-Left-Below-TextArea">
              <div className="mb-2 font-semibold">Promotions</div>
              <div>
                {data?.promotions.length <= 3 ? (
                  data?.promotions.map((item, index) => {
                    return (
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <div>{item}</div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div>{data?.promotions[0]}</div>
                    </div>
                    <div className="flex flex-row">
                      <div>{data?.promotions[1]}</div>
                    </div>
                    <div className="flex flex-row">
                      <div>{data?.promotions[2]}</div>
                    </div>
                    <Popover
                      content={
                        <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                          {data?.promotions?.map((item, index) => {
                            if (index >= 3) return <div>{item}</div>
                          })}
                        </span>
                      }
                    >
                      <div className="ml-1" style={{ color: '#1890FF' }}>{` + ${
                        data?.promotions?.length - 3
                      } more`}</div>
                    </Popover>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="Product360-Info-Right"
          style={{ borderLeft: '1px solid #f0f0f0', padding: '0px 0px 0px 16px' }}
        >
          <div className="flex flex-row justify-between mb-4 items-center">
            <div className="font-semibold">Product Performance</div>
            <div
              className="product360-info-item-ranking"
              // style={{
              //   backgroundColor: `var(--${data?.product_performance_status?.toLocaleLowerCase()}_6)`,
              // }}
            >
              {/* {data?.product_performance_status} */}
              <StatusBadge status={data?.product_performance_status}></StatusBadge>
            </div>
          </div>
          <div>
            <div className="flex flex-row justify-between mb-2">
              <div>Avg Rating</div>
              <div className="text-light">{data?.avg_rank}</div>
            </div>
            {data?.performance_rankings.map((item: any, index: number) => {
              return (
                <div className="flex flex-row justify-between mb-2">
                  <div>{item?.title}</div>
                  <div className="text-light ml-2" style={{ width: 58, textAlign: 'end' }}>
                    {item?.value}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Card>
    )
  }, [data])
  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active className="p-4">
      <div>{renderCard}</div>
    </Skeleton>
  )
}

export default Product360Info
