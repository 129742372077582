import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'
import {
  getCampaignOverviewKeyMetrics,
  getCampaignOverviewCharts,
} from 'Services/Analytics/campaign-analytics'
import CustomerInsights from './CustomerInsights'
import BarLineChartTemplate from '../../../Components/BarLineChart.Template'
import KeyMetricsTemplate from '../../../Components/KeyMetrics.Template'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { BY_TIME_METRICS, PI_DIMENSION, PI_METRIC } from 'Pages/Analytics/constants'
import { CampaignPivotTypes } from 'Services/Analytics/Pivot.service'
import LoadingIcon from 'Components/LoadingIcon'

const Body = () => {
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)

  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })

  const currentPage = window?.location?.href?.split('/')[4]?.split('?')[0]

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>

  return (
    <div className="my-4">
      <div style={{ minHeight: 300 }} ref={ref1} className="bg-white p-4">
        <div className="mb-4 font-semibold">Key Metrics</div>
        {ref1InView && <KeyMetricsTemplate handleDataFunction={getCampaignOverviewKeyMetrics} />}
      </div>

      <div style={{ height: 600 }} ref={ref2} className="bg-white p-4 mt-4 overflow-x-scroll">
        <div className="mb-4 font-semibold">Running Campaign Performance Comparision </div>
        {ref2InView && (
          <div style={{ height: 500, width: '500%' }}>
            <BarLineChartTemplate
              chartCode="campaign_performance"
              handleDataFunction={getCampaignOverviewCharts}
            />
          </div>
        )}
      </div>
      <div style={{ height: 400 }} ref={ref3} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Channel Performance in general</div>
        {ref3InView && (
          <div style={{ minHeight: 300 }}>
            <BarLineChartTemplate
              chartCode="channel_performance"
              handleDataFunction={getCampaignOverviewCharts}
            />
          </div>
        )}
      </div>

      <div style={{ minHeight: 200 }} ref={ref4} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Campaign Perfomance by Time</div>
        {ref4InView && (
          <PivotTableTemplate
            currentPage={currentPage}
            hideColumnSelects
            type={CampaignPivotTypes.ByTime}
            defaultDimensions={[{ code: 'campaign' }, { code: 'date' }]}
            defaultMetrics={BY_TIME_METRICS}
          />
        )}
      </div>
      {/* <div style={{ minHeight: 200 }} ref={ref5} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Session</div>
        {ref5InView && (
          <PivotTableTemplate
            defaultDimensions={[{ code: 'utm_campaign' }]}
            defaultMetrics={[
              { code: 'ctr' },
              { code: 'cr' },
              { code: 'aov' },
              { code: 'returned' },
              { code: 'cancelled' },
              { code: 'revenue' },
              { code: 'spend' },
            ]}
          />
        )}
      </div> */}
      {/* <div style={{ minHeight: 200 }} ref={ref6} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Channels</div>
        {ref6InView && (
          <PivotTableTemplate
            defaultDimensions={[{ code: 'krb_channel' }]}
            defaultMetrics={[
              { code: 'ctr' },
              { code: 'cr' },
              { code: 'aov' },
              { code: 'returned' },
              { code: 'cancelled' },
              { code: 'revenue' },
              { code: 'spend' },
            ]}
          />
        )}
      </div> */}
      <div style={{ minHeight: 200 }} ref={ref7} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Product Insight</div>
        {ref7InView && (
          <PivotTableTemplate
            currentPage={currentPage}
            hideColumnSelects
            type={CampaignPivotTypes.ProductInsight}
            defaultDimensions={PI_DIMENSION}
            defaultMetrics={PI_METRIC}
          />
        )}
      </div>

      <div ref={ref8} className="bg-white mt-4 >CustomerInsight">
        <div className="mb-4  p-4 font-semibold">Customer Insight</div>
        {ref8InView && <CustomerInsights />}
      </div>

      {/* <div className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Audience Funnel</div>
      </div> */}

      <div ref={ref9} className="bg-white p-4 mt-4">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref9InView && <PivotTableTemplate hideColumnSelects currentPage={currentPage} />}
      </div>
    </div>
  )
}

export default Body
