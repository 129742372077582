import React from 'react'
import { Typography, Tooltip } from 'antd'
import moment from 'moment'

import { capitalizeFirstLetter } from 'Utils'

import { CampaignStatus } from 'CampaignTypes'
import { systemColor } from 'DesignSystem/Colors'

const { Text } = Typography

const STATUS_COLORS = {
  init: 'greekBlue',
  waiting: 'gold',
  running: 'green',
  completed: 'blue',
  deleted: 'red',
}

const METRICS = [
  { value: 'impression', label: 'Impression' },
  { value: 'open', label: 'Open' },
  { value: 'click', label: 'Clicks' },
  { value: 'revenue', label: 'Revenue' },
  { value: 'cost', label: 'Cost' },
  // { value: 'performance', label: 'Performance' },
]

export const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 100,
    render: (value) => {
      if (value) return value
      else return 'N/A'
    },
  },
  {
    title: 'Campaign Name',
    dataIndex: 'name',
    width: 200,
    align: 'left',
    sorter: true,
    render: (campaign) => (
      <Tooltip title={campaign}>
        <Text className="campaign-title" strong>
          {campaign}
        </Text>
      </Tooltip>
    ),
    fixed: 'left',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 150,
    sorter: true,
    render: (status: string) => (
      <span
        className="font-semibold rounded-sm text-sm py-1 px-2"
        style={{
          width: 'fit-content',
          display: 'inline-block',
          textAlign: 'left',
          color: `${
            STATUS_COLORS[status] === systemColor.geekblue_6
              ? systemColor.geekblue_6
              : systemColor[STATUS_COLORS[status] + '_6']
          }`,
          backgroundColor: `${
            STATUS_COLORS[status] === systemColor.geekblue_1
              ? systemColor.greekBlue_1
              : systemColor[STATUS_COLORS[status] + '_1']
          }`,
        }}
      >
        {capitalizeFirstLetter(status)}
      </span>
      // <span
      //   className={`font-semibold rounded-sm text-sm py-1 px-2 bg-${STATUS_COLORS[status]}_1 text-${STATUS_COLORS[status]}_6`}
      // >
      //   {capitalizeFirstLetter(status)}
      // </span>
    ),
  },
  ...METRICS.map((metric) => ({
    title: metric.label,
    dataIndex: 'id',
    width: 150,
    mapping: true,
    code: metric.value,
    // render: (value) => {
    //   if (value) return value
    //   else return '0'
    // },
  })),
  {
    title: 'CR',
    mapping: true,
    code: 'cr',
    dataIndex: 'id',
    width: 100,
    render: (value) => {
      if (typeof value === 'number') return value.toFixed(2).toLocaleString() + '%'
      else return '0'
    },
  },

  {
    title: 'Start Date',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 200,
  },
]

export const WEBSITECOLUMNS = [
  {
    title: 'Campaign',
    dataIndex: 'campaign_name',
    sorter: true,
    align: 'left',
    width: 250,
    render: (campaign) =>
      campaign ? (
        <Tooltip title={campaign}>
          <Text className="campaign-title" strong>
            {campaign}
          </Text>
        </Tooltip>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 150,
    sorter: true,
    render: (status) =>
      status ? (
        <span
          className="font-semibold rounded-sm text-sm py-1 px-2"
          style={{
            width: 'fit-content',
            display: 'inline-block',
            textAlign: 'left',
            color: `${
              STATUS_COLORS[status] === systemColor.geekblue_6
                ? systemColor.geekblue_6
                : systemColor[STATUS_COLORS[status] + '_6']
            }`,
            backgroundColor: `${
              STATUS_COLORS[status] === systemColor.geekblue_1
                ? systemColor.greekBlue_1
                : systemColor[STATUS_COLORS[status] + '_1']
            }`,
          }}
        >
          {capitalizeFirstLetter(status)}
        </span>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Cost Per Click',
    dataIndex: 'cpc',
    width: 180,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Click',
    dataIndex: 'click',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Actual Rev',
    dataIndex: 'actual_revenue',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'AOV',
    dataIndex: 'aov',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Cancellation Rate',
    dataIndex: 'cancellation_rate',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CIR',
    dataIndex: 'cir',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Cost Per Deliver/Impression',
    dataIndex: 'cpi',
    width: 200,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Cost Per Order',
    dataIndex: 'cpo',
    width: 180,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CR Online',
    dataIndex: 'cr_online',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Deliver',
    dataIndex: 'deliver',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Discount Rate',
    dataIndex: 'discount_rate',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Gross Margin',
    dataIndex: 'gross_margin',
    width: 120,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Gross Profit',
    dataIndex: 'gross_profit',
    width: 120,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Total Item',
    dataIndex: 'item',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Return Rate',
    dataIndex: 'return_rate',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Return Rev',
    dataIndex: 'return_revenue',
    width: 120,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Session',
    dataIndex: 'session',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Transaction',
    dataIndex: 'transaction',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
]

export const EMAILCOLUMNS = [
  {
    title: 'Campaign',
    dataIndex: 'campaign_name',
    sorter: true,
    align: 'left',
    width: 200,
    render: (campaign) =>
      campaign ? (
        <Tooltip title={campaign}>
          <Text className="campaign-title" strong>
            {campaign}
          </Text>
        </Tooltip>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 150,
    sorter: true,
    render: (status) =>
      status ? (
        <span
          className="font-semibold rounded-sm text-sm py-1 px-2"
          style={{
            width: 'fit-content',
            display: 'inline-block',
            textAlign: 'left',
            color: `${
              STATUS_COLORS[status] === systemColor.geekblue_6
                ? systemColor.geekblue_6
                : systemColor[STATUS_COLORS[status] + '_6']
            }`,
            backgroundColor: `${
              STATUS_COLORS[status] === systemColor.geekblue_1
                ? systemColor.greekBlue_1
                : systemColor[STATUS_COLORS[status] + '_1']
            }`,
          }}
        >
          {capitalizeFirstLetter(status)}
        </span>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'View',
    dataIndex: 'view',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Click',
    dataIndex: 'click',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CR Online',
    dataIndex: 'cr_online',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Deliver',
    dataIndex: 'deliver',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Email Unsubcribe',
    dataIndex: 'email_unsub',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Reach',
    dataIndex: 'reach',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
]

export const SMSCOLUMNS = [
  {
    title: 'Campaign',
    dataIndex: 'campaign_name',
    sorter: true,
    align: 'left',
    with: 200,
    render: (campaign) =>
      campaign ? (
        <Tooltip title={campaign}>
          <Text className="campaign-title" strong>
            {campaign}
          </Text>
        </Tooltip>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 150,
    sorter: true,
    render: (status) =>
      status ? (
        <span
          className="font-semibold rounded-sm text-sm py-1 px-2"
          style={{
            width: 'fit-content',
            display: 'inline-block',
            textAlign: 'left',
            color: `${
              STATUS_COLORS[status] === systemColor.geekblue_6
                ? systemColor.geekblue_6
                : systemColor[STATUS_COLORS[status] + '_6']
            }`,
            backgroundColor: `${
              STATUS_COLORS[status] === systemColor.geekblue_1
                ? systemColor.greekBlue_1
                : systemColor[STATUS_COLORS[status] + '_1']
            }`,
          }}
        >
          {capitalizeFirstLetter(status)}
        </span>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },

  {
    title: 'Cost',
    dataIndex: 'cost',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Coupon Used',
    dataIndex: 'coupon_used',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Total Item',
    dataIndex: 'item',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Transaction',
    dataIndex: 'transaction',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },

  {
    title: 'Deliver',
    dataIndex: 'deliver',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },

  {
    title: 'Reach',
    dataIndex: 'reach',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
]

export const STATUS: { label: string; value: string }[] = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const IS_ACTIVE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const CHANNELS = [
  { label: 'Published', value: 'sas' },
  { label: 'Draft', value: 'init' },
]
export const TYPES = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]
export const DATE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'init' },
]

export const APP_PUSH_METRIC = [
  {
    title: 'Campaign',
    dataIndex: 'campaign_name',
    sorter: true,
    align: 'left',
    width: 250,
    render: (campaign) =>
      campaign ? (
        <Tooltip title={campaign}>
          <Text className="campaign-title" strong>
            {campaign}
          </Text>
        </Tooltip>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 150,
    sorter: true,
    render: (status) =>
      status ? (
        <span
          className="font-semibold rounded-sm text-sm py-1 px-2"
          style={{
            width: 'fit-content',
            display: 'inline-block',
            textAlign: 'left',
            color: `${
              STATUS_COLORS[status] === systemColor.geekblue_6
                ? systemColor.geekblue_6
                : systemColor[STATUS_COLORS[status] + '_6']
            }`,
            backgroundColor: `${
              STATUS_COLORS[status] === systemColor.geekblue_1
                ? systemColor.greekBlue_1
                : systemColor[STATUS_COLORS[status] + '_1']
            }`,
          }}
        >
          {capitalizeFirstLetter(status)}
        </span>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Start Time',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 200,
  },
  // {
  //   title: 'End Time',
  //   dataIndex: 'updated_at',
  //   sorter: true,
  //   render: (text) => moment(text).format('DD-MM-YYYY'),
  //   width: 200,
  // },
  {
    title: 'Deliver',
    dataIndex: 'deliver',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Impression',
    dataIndex: 'impression',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'View',
    dataIndex: 'view',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Close',
    dataIndex: 'close',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Click',
    dataIndex: 'click',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Session',
    dataIndex: 'session',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Transaction',
    dataIndex: 'transaction',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Total Item',
    dataIndex: 'total_item',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'AOV',
    dataIndex: 'aov',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CPI (Cost Per Deliver/Impression)',
    dataIndex: 'cpi',
    width: 250,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CPC (Cost Per Click)',
    dataIndex: 'cpc',
    width: 180,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CPO (Cost Per Order)',
    dataIndex: 'cpo',
    width: 200,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CiR (Continous Improvement in Return)',
    dataIndex: 'cir',
    width: 300,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Coupon Used',
    dataIndex: 'coupon_used',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Email Unsubcribe (All Event Group)',
    dataIndex: 'email_unsubcribe',
    width: 300,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Redeem rate',
    dataIndex: 'redeem_rate',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Channel',
    dataIndex: 'channel',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Drop off',
    dataIndex: 'drop_off',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CR Online',
    dataIndex: 'cr_online',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CR Offline',
    dataIndex: 'cr_offline',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Total Discount',
    dataIndex: 'total_discount',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Discount Rate',
    dataIndex: 'discount_rate',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Actual Rev',
    dataIndex: 'actual_rev',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Return Rev',
    dataIndex: 'return_rev',
    width: 120,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Cancellation Rate',
    dataIndex: 'cancellation_rate',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Return Rate',
    dataIndex: 'return_rate',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Gross Profit',
    dataIndex: 'gross_profit',
    width: 120,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Gross Margin',
    dataIndex: 'gross_margin',
    width: 120,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
]

export const FACEBOOK_ADS = [
  {
    title: 'Campaign',
    dataIndex: 'campaign_name',
    sorter: true,
    align: 'left',
    width: 200,
    render: (campaign) =>
      campaign ? (
        <Tooltip title={campaign}>
          <Text className="campaign-title" strong>
            {campaign}
          </Text>
        </Tooltip>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Optimization Goal',
    dataIndex: 'optimization_goal',
    width: 200,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Reach',
    dataIndex: 'reach',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Impression',
    dataIndex: 'impression',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Ads Clicks',
    dataIndex: 'ads_clicks',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Shortlink Clicks',
    dataIndex: 'shortlink_clicks',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CTR_All',
    dataIndex: 'ctr_all',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CTR_Shortlink',
    dataIndex: 'ctr_shortlink',
    width: 150,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CPR (Cost per Thousand reach)',
    dataIndex: 'cpr',
    width: 250,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CPM',
    dataIndex: 'cpm',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CPC (Cost Per Click)',
    dataIndex: 'cpc',
    width: 250,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Spend',
    dataIndex: 'spend',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'CR',
    dataIndex: 'cr',
    width: 100,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
  {
    title: 'Purchase Online/Offline',
    dataIndex: 'purchase_online_offline',
    width: 250,
    render: (value) => {
      if (value) return value.toLocaleString()
      else return '0'
    },
  },
]
