import React, { useCallback, useEffect, useState } from 'react'
import { Select, Skeleton, Tabs } from 'antd'
import {
  getPivotCateList,
  getProductOverviewRevenueTables,
  getProductPivotCateList,
} from 'Services/Analytics/product-analytics'
import CateTable from 'Components/Charts/CateTable/CateTable'
import { debounce } from 'lodash'
import axios from 'axios'
const { TabPane } = Tabs
const { Option } = Select

const ChartRow_3 = () => {
  const [keyword, setKeyword] = useState<string>('')
  const [searchList, setSearchList] = useState<{ key: string; title: string }[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [cateId, setCateId] = useState<any>('root')
  const [isSearch, setIsSearch] = useState<boolean>(false)
  let cancelToken
  const handleSearch = async (v) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    setLoading(true)
    try {
      setSearchList([])
      const resp = await getProductPivotCateList({
        keyword: v,
        cancelTokenSource: cancelToken,
      })
      setLoading(false)
      setSearchList(resp.data)
    } catch (err: any) {
      // message.error('Something went wrong')
    }
  }

  const debounceDropDown = useCallback(
    debounce((nextValue) => {
      handleSearch(nextValue)
      setIsSearch(false)
    }, 1000),
    []
  )
  useEffect(() => {
    isSearch && debounceDropDown(keyword)
  }, [isSearch])

  const options = searchList?.map((item) => {
    return <Option value={item?.key}>{item?.title}</Option>
  })
  const renderHeader = () => {
    return (
      <div className="flex mb-2 CatePivot-header">
        <Select
          allowClear
          defaultActiveFirstOption={false}
          onClear={() => setCateId('root')}
          style={{ width: 272 }}
          loading={loading}
          filterOption={false}
          notFoundContent={null}
          showSearch
          placeholder="Search"
          className="search-bar"
          onSearch={(e) => {
            setKeyword(e)
          }}
          onSelect={(e) => {
            setCateId(e)
          }}
          dropdownStyle={{ padding: 8 }}
          dropdownRender={(menu) => (
            <Skeleton loading={loading} active>
              {menu}
            </Skeleton>
          )}
          onInputKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              setIsSearch(true)
            } else if (e.key === ' ') {
              setIsSearch(true)
            }
          }}
        >
          {options}
        </Select>
      </div>
    )
  }

  return (
    <div className=" col-span-1 CatePivot">
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold text-lg">Revenue by Sale Channel and Delivery Method</div>
        {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
      </div>

      <Tabs defaultActiveKey="1" tabBarExtraContent={renderHeader()}>
        <TabPane tab="Product Sale by Sale Channel" key="1">
          <CateTable
            category_id={cateId}
            type={'product_sale_by_sale_channel'}
            handleDataFunction={getProductOverviewRevenueTables}
          ></CateTable>
        </TabPane>
        <TabPane tab="Product Sale by Delivery Method" key="2">
          <CateTable
            category_id={cateId}
            type={'product_sale_by_delivery_method'}
            handleDataFunction={getProductOverviewRevenueTables}
          ></CateTable>
        </TabPane>
        <TabPane tab="Sub-Cate Sale by Sale Channel" key="3">
          <CateTable
            category_id={cateId}
            type={'subcate_sale_by_sale_channel'}
            handleDataFunction={getProductOverviewRevenueTables}
          ></CateTable>
        </TabPane>
        <TabPane tab="Sub-Cate Sale by Delivery Method" key="4">
          <CateTable
            category_id={cateId}
            type={'subcate_sale_by_delivery_method'}
            handleDataFunction={getProductOverviewRevenueTables}
          ></CateTable>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ChartRow_3
