import React, { useState, useEffect } from 'react'
import './index.scss'
import { useInView } from 'react-intersection-observer'
import { getCustomerOverviewKeyMetrics } from 'Services/Analytics/customer-analytics'
import { useHistory } from 'react-router-dom'

import KeyMetricsTemplate from '../../../Components/KeyMetrics.Template'

import ChartRow1 from './ChartRow_5'
import ChartRow2 from './ChartRow2'
import PivotRow1 from './PivotRow_1'
import PivotRow2 from './PivotRow_2'
import PivotRow3 from './PivotRow_3'
import PivotRow4 from './PivotRow_4'
import ChartPivot from './Chart_Pivot'
import CustomizeSection from './CustomizeSection'

import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'
import { getProductOverviewKeyMetrics } from 'Services/Analytics/product-analytics'
import PivotTableTemplate from 'Pages/Analytics/Components/PivotTable.Template'
import { ProductPivotTypes } from 'Services/Analytics/Pivot.service'
import ChartRow_1 from './ChartRow_1'
// import CateTable from 'Components/Charts/CateTable/CateTable'
import ChartRow_2 from './ChartRow_2'
import ChartRow_3 from './ChartRow_3'
import ChartRow_4 from './ChartRow_4'
import ChartRow_5 from './ChartRow_5'
import ChartRow_6 from './ChartRow_6'
import ChartRow_7 from './ChartRow_7'
import ChartRow_8 from './ChartRow_8'
import ChartRow_9 from './ChartRow_9'
import ChartRow_10 from './ChartRow_10'
import ProductOverviewFunnel from 'Pages/Analytics/Components/ProductOverviewFunnelTemplate'
import { getProductOverviewFunnelData } from 'Services/Analytics/saleChannel-analytics'
// import ChartRow_10 from './ChartRow_10'

const ProductOverviewBody = () => {
  const history = useHistory()
  const [isResetting, setIsResetting] = useState(false)
  const { ref: ref1, inView: ref1InView } = useInView({ triggerOnce: true })
  const { ref: ref2, inView: ref2InView } = useInView({ triggerOnce: true })
  const { ref: ref3, inView: ref3InView } = useInView({ triggerOnce: true })
  const { ref: ref4, inView: ref4InView } = useInView({ triggerOnce: true })
  const { ref: ref5, inView: ref5InView } = useInView({ triggerOnce: true })
  const { ref: ref6, inView: ref6InView } = useInView({ triggerOnce: true })
  const { ref: ref7, inView: ref7InView } = useInView({ triggerOnce: true })
  const { ref: ref8, inView: ref8InView } = useInView({ triggerOnce: true })
  const { ref: ref9, inView: ref9InView } = useInView({ triggerOnce: true })
  const { ref: ref10, inView: ref10InView } = useInView({ triggerOnce: true })
  const { ref: ref11, inView: ref11InView } = useInView({ triggerOnce: true })
  const { ref: ref12, inView: ref12InView } = useInView({ triggerOnce: true })
  const { ref: ref13, inView: ref13InView } = useInView({ triggerOnce: true })
  const { ref: ref14, inView: ref14InView } = useInView({ triggerOnce: true })
  const { ref: ref15, inView: ref15InView } = useInView({ triggerOnce: true })
  const { ref: ref16, inView: ref16InView } = useInView({ triggerOnce: true })
  const { ref: ref17, inView: ref17InView } = useInView({ triggerOnce: true })

  useEffect(() => {
    setIsResetting(true)

    setTimeout(() => {
      setIsResetting(false)
    }, 10)
  }, [history.location.search])

  if (isResetting) return <div className="w-full" style={{ height: 1000 }}></div>

  return (
    <div className="my-4">
      <div
        style={{ minHeight: 200 }}
        ref={ref1}
        className="bg-white p-4 product-analytics-overview-metrics"
      >
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold text-base">Key Metric</div>
          {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        {ref1InView && (
          <div className="flex flex-col">
            <KeyMetricsTemplate handleDataFunction={getProductOverviewKeyMetrics} />
            <ChartRow_1></ChartRow_1>
          </div>
        )}
        {/* <div ref={ref10}>
        {ref10InView && }
        </div> */}
      </div>
      <div className="bg-white p-4 mt-4 relative" ref={ref2}>
        {ref2InView && <ChartRow_2></ChartRow_2>}
      </div>
      <div className="bg-white p-4 mt-4 relative" ref={ref3}>
        {ref3InView && <ChartRow_3></ChartRow_3>}
      </div>
      <div className="bg-white p-4 mt-4 relative" ref={ref4}>
        {ref4InView && <ChartRow_4></ChartRow_4>}
      </div>
      <div style={{ minHeight: 340 }} ref={ref5} className="bg-white grid grid-cols-3 gap-4 mt-4">
        {ref5InView && <ChartRow_5 />}
      </div>
      {/* <div className="bg-white p-4 mt-4 relative" ref={ref6}>
        {ref6InView && <ChartRow_6></ChartRow_6>}
      </div> */}
      {/* <div className="bg-white p-4 relative" ref={ref7}>
        {ref7InView && <ChartRow_7></ChartRow_7>}
      </div> */}
      {/* <div className="bg-white p-4 relative mt-4" ref={ref8}>
        {ref8InView && <ChartRow_8></ChartRow_8>}
      </div> */}
      <div style={{ minHeight: 600 }} ref={ref9} className="bg-white mt-4 grid grid-cols-3">
        {ref9InView && <ChartRow_9 />}
      </div>
      <div className="bg-white p-4 relative" ref={ref10}>
        {ref10InView && <ChartRow_10></ChartRow_10>}
      </div>

      {/* <div style={{ minHeight: 300 }} ref={ref11} className="bg-white mt-4">
        {ref11InView && (
          <div className="bg-white p-4 col-span-3 shadow-div_b">
            <div className="mb-4 font-semibold">Audience Funnel on Digital Channel (Web/App)</div>
            <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
              <ProductOverviewFunnel
                firstColumn="Total Deliver = Impression"
                analytic_type="audience_funnel_on_digital_channel"
                handleDataFunction={getProductOverviewFunnelData}
              ></ProductOverviewFunnel>
            </div>
          </div>
        )}
      </div> */}
      {/* <div style={{ minHeight: 300 }} ref={ref12} className="bg-white">
        {ref12InView && (
          <div className="bg-white p-4 col-span-3 shadow-div_b">
            <div className="mb-4 font-semibold">Audience Funnel on Advertising</div>
            <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ minHeight: 300 }}>
              <ProductOverviewFunnel
                firstColumn="Engage (SMS, Notification)"
                analytic_type="audience_funnel_on_digital_channel"
                handleDataFunction={getProductOverviewFunnelData}
              ></ProductOverviewFunnel>
            </div>
          </div>
        )}
      </div> */}
      <div style={{ minHeight: 300 }} ref={ref8} className="bg-white mt-4 grid grid-cols-3">
        {ref8InView && <ChartPivot />}
      </div>
      <div style={{ minHeight: 182 }} ref={ref13} className="bg-white p-4 mt-4 ">
        <div className="mb-4 font-semibold">Customize Section</div>
        {ref13InView && (
          <PivotTableTemplate
            type={ProductPivotTypes.ProductCustomizeSection}
            currentPage={window?.location?.href?.split('/')[4]?.split('?')[0]}
          />
        )}
      </div>
    </div>
  )
}

export default ProductOverviewBody
