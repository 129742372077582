import React, { useEffect, useMemo } from 'react'
import { BarChartOutlined } from '@ant-design/icons'

import {
  APP_PUSH_METRIC,
  COLUMNS,
  EMAILCOLUMNS,
  FACEBOOK_ADS,
  SMSCOLUMNS,
  WEBSITECOLUMNS,
} from './constants'
import Table from 'Components/Table2.0'
import { RecordType } from 'Components/Table2.0/index.d'
import { Filters } from './index'
import useColumns from './useColumns'

export type props = {
  channelList: { label: string; value: string }[]
  placementList: { label: string; value: string }[]
  setFilters: (filters: React.SetStateAction<Filters>) => void
  filters: Filters
  handleTableChange: (tablePagination: any, _: any, sorter: any) => void
  handleEdit: (record: RecordType) => void
  handleStatistics: (record: RecordType) => void
}

const CampaignListView: React.FC<props> = ({
  channelList,
  placementList,
  setFilters,
  filters,
  handleTableChange,
  handleEdit,
  handleStatistics,
}) => {
  const { columns, setData, loading } = useColumns({ channel: filters?.channel?.split(`\"`) })

  const memoTable = useMemo(
    () => (
      <Table
        dropdown={{
          fields: [
            {
              label: 'Channel',
              value: 'channel',
              options: channelList,
              type: 'DROPDOWN',
              onClick: ({ key, value }) =>
                setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
            },
            {
              label: 'Placements',
              value: 'placement',
              options: placementList,
              type: 'DROPDOWN',
              onClick: ({ key, value }) =>
                setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
            },
            // {
            //   label: 'Tags',
            //   value: 'tags',
            //   options: placementList,
            //   type: 'DROPDOWN',
            //   onClick: ({ key, value }) =>
            //     setFilters({ ...filters, page: 1, [key]: JSON.stringify(value) }),
            // },
          ],
          value: {
            ...filters,
            placement: JSON.parse(filters.placement),
            channel: JSON.parse(filters.channel),
          },
        }}
        datePicker={{
          from_date: filters.from_date,
          to_date: filters.to_date,
          onChange: ({ from_date, to_date }) => {
            setFilters((prev) => ({
              ...prev,
              from_date: from_date || prev.from_date,
              to_date: to_date || prev.to_date,
            }))
          },
        }}
        fetchFrom={{ url: '/campaigns/', key: 'results' }}
        search={{
          keyword: filters.query,
          onSearch: (query) => {
            setFilters((prev) => ({ ...prev, query, page: 1 }))
          },
        }}
        // columns={computedColumns(filters?.channel?.split(`\"`))}
        columns={columns}
        setDataCallback={setData}
        handleTableChange={handleTableChange}
        rowActions={{
          onDoubleClick: handleEdit,
          fixed: 'right',
          actions: [
            {
              icon: <BarChartOutlined />,
              attribute: { type: 'highlight', label: 'Analytics' },
              onClick: handleStatistics,
            },
          ],
        }}
      />
    ),
    [filters, loading]
  )

  return memoTable
}

export default CampaignListView
