import React from 'react'
import BarChartTemplate from '../../Components/BarChart.Template'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'

import { getProduct360Charts } from 'Services/Analytics/product-analytics'
import StackedBarLine from 'Pages/Analytics/SaleAnalytics/Components/StackedBarLine.Template'
import useFilters from '../FiltersHeader/useFilters'

const ChartRow1 = () => {
  const { id } = useParams()
  const history = useHistory()
  const { filters: defaultFilters } = useFilters()
  const queryObject = queryString.parse(history.location.search)
  const frequency = queryObject?.frequency?.toString() || defaultFilters?.frequency
  return (
    <>
      <div className="col-span-3 p-4 bg-white shadow-div_b">
        <div className="mb-4 font-semibold">Sale and Customer by frequency ({frequency})</div>
        <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ minHeight: 300 }}>
          <StackedBarLine
            chartCode="product_sale_frequency"
            handleDataFunction={getProduct360Charts}
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow1
