import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Empty, Skeleton, Table } from 'antd'
import { columns } from './ChartRow_1.constants'
import axios from 'axios'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { DataSourceContext } from 'Pages/Analytics/Products/Overview/index'
import { getProductOverviewMetricTables } from 'Services/Analytics/product-analytics'
interface DataType {
  key: number
  product_performance: string
  no_of_sku: number
  no_of_supplier_by_status?: number
  total: number
  new: number
  star: number
  yellow: number
  red: number
  green: number
  deactive: number
  clear: number
  promotion: number
}
const ChartRow_1 = () => {
  const history = useHistory()
  const dataSource = React.useContext(DataSourceContext)
  const [error, setError] = useState('')
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { filters: defaultFilters } = useFilters()
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        // setChart(CHART)
        const resp = await getProductOverviewMetricTables({
          type: 'product_x_supplier_by_status',
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          filter_uuid: queryObject?.filter_uuid?.toString() || '',
          source: dataSource?.toString() || '',
          // cancelToken: cancelTokenSource?.token,
        })
        // console.log('resp', resp)
        setData(resp?.data?.results)
      } catch (err: any) {
        console.log(err)
        if (err.message === 'cancelled') return
        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'No data available'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()
    return () => {
      // setData()
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    queryObject.filter_uuid,
    dataSource,
  ])
  const renderChart = useMemo(() => {
    if (!data) return <Empty />

    return (
      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="middle"
        scroll={{
          x: 'calc(700px + 50%)',
          y: 240,
        }}
        pagination={false}
      />
    )
  }, [data])

  if (error) return <Alert message={error} type="error" />

  return (
    <div className=" col-span-1 mt-8 ProductPerformanceAndSupplierStatus">
      <div className="mb-4 flex flex-col items-center">
        <div className="font-semibold self-baseline mb-4">
          Product Performance and Supplier by Status
        </div>
        {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        <Skeleton loading={isLoading} active>
          {data && renderChart}
        </Skeleton>
      </div>
    </div>
  )
}

export default ChartRow_1
