import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Skeleton } from 'antd'
import axios from 'axios'

import MetricCard from 'Components/MetricCard'
import { FiltersType } from 'AnalyticsTypes'
import { getProduct360V2KeyMetrics } from 'Services/Analytics/product-analytics'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'

const KeyMetrics = ({ analytic_type }) => {
  const history = useHistory()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const { filters: defaultFilters } = useFilters()
  const queryObject = queryString.parse(history.location.search)

  // const [metrics, setMetrics] = useState<
  //   {
  //     value: number
  //     unit: string
  //     title: string
  //     subvalue?: string | null
  //     display_name?: string
  //   }[]
  // >([])
  const [metrics, setMetrics] = useState<any>([])
  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const handleKeyMetrics = async () => {
      setIsLoading(true)
      try {
        setError('')
        const resp = await getProduct360V2KeyMetrics({
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          barcode: id,
          analytic_type: analytic_type,
          // cancelToken: cancelTokenSource.token,
        })

        setMetrics(resp.data.data)
      } catch (err: any) {
        if (err.message === 'cancelled') {
          return
        }

        setError(err?.response?.data?.detail || 'Some thing went wrong')
      }
      setIsLoading(false)
    }

    handleKeyMetrics()
    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [id, analytic_type, queryObject?.frequency?.toString()])

  if (error) return <Alert message={error} type="error" />

  return (
    <Skeleton loading={isLoading} active>
      {analytic_type == 'sale_channel' ? (
        <div>
          {metrics.map((m, index) => (
            <div className="metric-cards-wrapper">
              {m?.map((n, index) => (
                <>
                  {index == 0 ? (
                    <div
                      className="title-wrapper box-0"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                        boxShadow: 'var(--div_rb)',
                      }}
                    >
                      <span className="value">{n?.value}</span>
                    </div>
                  ) : (
                    <MetricCard
                      key={index}
                      className={`box-${index + 1}`}
                      title={n.display_name ? n.display_name : n.title}
                      unit={n.unit}
                      value={n.value}
                    ></MetricCard>
                  )}
                </>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className="metric-cards-wrapper">
          {metrics.map((m, index) => (
            <MetricCard
              key={index}
              className={`box-${index + 1}`}
              title={m.display_name ? m.display_name : m.title}
              unit={m.unit}
              value={m.value}
            ></MetricCard>
          ))}
        </div>
      )}
    </Skeleton>
  )
}

export default KeyMetrics
