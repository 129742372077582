import React, { useCallback, useEffect, useState } from 'react'
import { Input, Select, Skeleton, Tabs } from 'antd'
import CateTable from 'Components/Charts/CateTable/CateTable'
import TableHeader from 'Components/Table2.0/Components/Header'
import { sortbyOptions } from './ChartRow_1.constants'
import { ReactComponent as IconGrid } from 'Assets/images/icons/grid.svg'
import { ReactComponent as IconListView } from 'Assets/images/icons/listView.svg'
import {
  getPivotCateList,
  getProductOverviewTables,
  getProductPivotCateList,
} from 'Services/Analytics/product-analytics'
import axios from 'axios'
import { debounce, isElement } from 'lodash'
const { TabPane } = Tabs
const { Option } = Select

const ChartRow_2 = () => {
  const [view, setChangeView] = useState('list')
  const [sortby, setSortby] = useState<any>('revenue')
  const [sortByLabel, setSortByLabel] = useState('Revenue')
  const [keyword, setKeyword] = useState<string>('')
  const [searchList, setSearchList] = useState<{ key: string; title: string }[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [cateId, setCateId] = useState<any>('root')
  const [isSearch, setIsSearch] = useState<boolean>(false)
  let cancelToken
  const handleSearch = async (v) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    setLoading(true)
    try {
      setSearchList([])
      const resp = await getProductPivotCateList({
        keyword: v,
        cancelTokenSource: cancelToken,
      })
      setLoading(false)
      setSearchList(resp.data)
    } catch (err: any) {
      // message.error('Something went wrong')
    }
  }

  const debounceDropDown = useCallback(
    debounce((nextValue) => {
      handleSearch(nextValue)
      setIsSearch(false)
    }, 1000),
    []
  )
  useEffect(() => {
    isSearch && debounceDropDown(keyword)
  }, [isSearch])

  const options = searchList?.map((item) => {
    return <Option value={item?.key}>{item?.title}</Option>
  })
  const renderHeader = () => {
    return (
      <div className="flex mb-2 CatePivot-header">
        <Select
          allowClear
          defaultActiveFirstOption={false}
          onClear={() => setCateId('root')}
          style={{ width: 272 }}
          loading={loading}
          filterOption={false}
          notFoundContent={null}
          showSearch
          placeholder="Search"
          className="search-bar"
          onSearch={(e) => {
            setKeyword(e)
          }}
          onSelect={(e) => {
            setCateId(e)
          }}
          dropdownStyle={{ padding: 8 }}
          dropdownRender={(menu) => (
            <Skeleton loading={loading} active>
              {menu}
            </Skeleton>
          )}
          onInputKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              setIsSearch(true)
            } else if (e.key === ' ') {
              setIsSearch(true)
            }
          }}
        >
          {options}
        </Select>
        <Select
          placeholder={`Sort by: ${sortByLabel} `}
          onSelect={(val) => {
            setSortby(val)
          }}
        >
          {sortbyOptions.map((item) => {
            return <Option value={item.value}>{item.label}</Option>
          })}
        </Select>
        <div className={`mt-1 iconCateTable ${view == 'list' && 'active'}`}>
          <div onClick={() => setChangeView('list')} className="p-2 rounded ml-2 ">
            <IconListView />
          </div>
        </div>
        <div className={`mt-1 iconCateTable ${view == 'grid' && 'active'}`}>
          <div onClick={() => setChangeView('grid')} className="p-2 rounded ml-2">
            <IconGrid />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className=" col-span-1 CatePivot">
      <div className="mb-4 flex justify-between items-center">
        <div className="font-semibold text-lg">Ranking</div>
        {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
      </div>

      <Tabs defaultActiveKey="1" tabBarExtraContent={renderHeader()}>
        <TabPane tab="Best Seller" key="1">
          <CateTable
            category_id={cateId}
            order_by={sortby}
            type={'best_seller'}
            view={view}
            handleDataFunction={getProductOverviewTables}
          ></CateTable>
        </TabPane>
        <TabPane tab="Brand Ranking" key="2">
          <CateTable
            category_id={cateId}
            order_by={sortby}
            type={'brand_ranking'}
            view={view}
            handleDataFunction={getProductOverviewTables}
          ></CateTable>
        </TabPane>
        <TabPane tab="Supplier Ranking" key="3">
          <CateTable
            category_id={cateId}
            order_by={sortby}
            type={'supplier_ranking'}
            view={view}
            handleDataFunction={getProductOverviewTables}
          ></CateTable>
        </TabPane>
        <TabPane tab="Sub-Cate Sale" key="4">
          <CateTable
            category_id={cateId}
            order_by={sortby}
            type={'sub_cate_sale'}
            view={view}
            handleDataFunction={getProductOverviewTables}
          ></CateTable>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ChartRow_2
