import React from 'react'
import {
  getCampaignOverviewKeyMetrics,
  getCampaignOverviewCharts,
} from 'Services/Analytics/campaign-analytics'
import BarChartTemplate from '../../../Components/BarChart.Template'
import MultiDoughnutChartTemplate from '../../../Components/MultiDoughnutChart.Template'
import BarLineChartTemplate from '../../../Components/BarLineChart.Template'

import { getCustomerOverviewCharts } from 'Services/Analytics/customer-analytics'
import { ReactComponent as RefreshIcon } from 'Assets/images/icons/refresh.svg'
import { ReactComponent as DownIcon } from 'Assets/images/icons/download.svg'
import { getProductOverviewCharts } from 'Services/Analytics/product-analytics'

const ChartRow_5 = () => {
  return (
    <>
      <div className="p-4 col-span-1 shadow-div_rb">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">{`Quantity & Revenue by Category`}</div>
          {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden">
          <MultiDoughnutChartTemplate
            chartCode="quantity_revenue_by_category"
            handleDataFunction={getProductOverviewCharts}
          />
        </div>
      </div>
      <div style={{ height: 310 }} className="col-span-2 bg-white p-4">
        <div className="mb-4 flex justify-between items-center">
          <div className="font-semibold">Purchase Statistic</div>
          {/* {/* <div className="flex text-brand_primary">
            <div className="mr-4">
              <RefreshIcon />
            </div>
            <div>
              <DownIcon />
            </div>
          </div> */}
        </div>
        <div className="h-full">
          <BarLineChartTemplate
            chartCode="purchase_statistic"
            handleDataFunction={getProductOverviewCharts}
            isHalf
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow_5
