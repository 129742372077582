import FiltersHeader from 'Pages/Analytics/Components/FiltersHeader'
import Latency from 'Pages/Analytics/Components/Latency'
import React, { useState, useEffect } from 'react'
import { DataNode } from 'antd/lib/tree'
import axios from 'axios'
import { Select } from 'antd'

import Body from './Body'
import { useStoreState } from 'easy-peasy'
import { createProductDimension } from 'Services/products'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import LoadingIcon from 'Components/LoadingIcon'

export const DataSourceContext = React.createContext('pcm')
export interface StoreNode extends DataNode {
  store_code?: string
  storeKey?: string
}
const { Option } = Select
const OPTIONS = [
  {
    value: 'pcm',
    label: (
      <span>
        Content Source: <span className=" font-semibold">PCM</span>
      </span>
    ),
  },
  {
    value: 'kdb',
    label: (
      <span>
        Content Source: <span className=" font-semibold">KDB</span>
      </span>
    ),
  },
]
let cancelTokenSource = axios.CancelToken.source()

const Overview = () => {
  const { filters, onChangeFilters } = useFilters()
  const [dataSource, setDataSource] = useState('pcm')
  const history = useHistory()
  const search = queryString.parse(history.location.search)
  const filterUuidParam = search?.filter_uuid
  const [errors, setErrors] = useState('')
  const { productCategoryGroup } = useStoreState((state) => state.dimensions)
  const [data, setData] = useState<StoreNode[] | any>([])
  const [isLoading, setLoadingCreate] = useState(true)
  useEffect(() => {
    if (!filterUuidParam) {
      if (productCategoryGroup) {
        const TreeData = productCategoryGroup[0]?.dimensions[0]?.values[0]?.tree.children
        const checkedKeyDefault = TreeData ? getKeyChildren(() => true)(TreeData) : []
        if (checkedKeyDefault.length) {
          onCreateProductDimension(checkedKeyDefault)
        }
        setData(TreeData)
      }
    } else {
      setLoadingCreate(false)
    }
  }, [productCategoryGroup])

  const onCreateProductDimension = async (value) => {
    // cancel prev request if not finished
    cancelTokenSource.cancel('cancel request')
    //generate new cancel token
    cancelTokenSource = axios.CancelToken.source()
    const PAYLOAD = {
      dimensions: `[{\"dimension\":{\"segments\":[{\"audience_Type\":\"audience\",\"type\":\"PRODUCT_CATEGORY\",\"operator\":\"IN_THE_FOLLOWING\",\"value\":\"${value.toString()}\",\"time_value\":\"\"}],\"condition\":\"AND\"},\"condition\":\"\"}]`,
    }
    setLoadingCreate(true)
    try {
      const res = await createProductDimension({ data: PAYLOAD, cancelTokenSource })
      if (res) {
        history.push({
          search: queryString.stringify({
            from_date: search?.from_date?.toString() || filters?.from_date,
            to_date: search?.to_date?.toString() || filters?.to_date,
            frequency: search?.frequency?.toString() || filters?.frequency,
            filter_uuid: res?.filter_uuid || '',
          }),
        })
      }
    } catch (err: any) {
      // no error warning with canceled requests
      if (err?.message === 'cancel request') return
      setErrors(err?.message)
    }
    setLoadingCreate(false)
  }

  const getKeyChildren = (condition) => (data) => {
    return data.reduce((acc, obj) => {
      if (obj.children) {
        return [...acc, ...getKeyChildren(condition)(obj.children)]
      } else if (condition(obj)) {
        return [...acc, obj.key]
      }
      // return acc
    }, [])
  }

  return (
    <div>
      <DataSourceContext.Provider value={dataSource}>
        <div className="AnalyticsWrapper">
          <div className="relative">
            <div className="absolute top-0 left-0">
              <Select
                className="w-[200px] h-full rounded-md text-primary_text frequency-select"
                value={dataSource || undefined}
                defaultValue={'pcm'}
                placeholder="Content Source"
                onChange={(v) => setDataSource(v)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {OPTIONS.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <FiltersHeader isUuid={true} loadingDefault={isLoading} />
          </div>
          {isLoading ? (
            <div className="w-full flex justify-center mt-4">
              <LoadingIcon />
            </div>
          ) : (
            <>
              <div className="mt-4 mb-4">
                <Latency />
              </div>

              <Body />
            </>
          )}
        </div>
      </DataSourceContext.Provider>
    </div>
  )
}

export default Overview
