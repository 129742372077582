import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { thousandsSeparator } from 'Utils/kFormatter'

interface DataType {
  key: number
  product_performance: string
  no_of_sku: number
  no_of_supplier_by_status?: number
  total: number
  new: number
  star: number
  yellow: number
  red: number
  green: number
  deactive: number
  clear: number
  promotion: number
  percentage_of_sku: number
  percentage_of_total: number
}
export const columns: ColumnsType<DataType> = [
  {
    title: 'Product Performance',
    dataIndex: 'product_performance',
    key: 'product_performance',
    width: 100,
    // fixed: 'left',
  },
  {
    title: 'No. of SKU',
    dataIndex: 'no_of_sku',
    key: 'no_of_sku',
    width: 112,
    // fixed: 'left',
    sorter: {
      compare: (a, b) => a.no_of_sku - b.no_of_sku,
      multiple: 1,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Percentage of SKU',
    dataIndex: 'percentage_of_sku',
    key: 'percentage_of_sku',
    width: 128,
    // fixed: 'left',
    sorter: {
      compare: (a, b) => a.percentage_of_sku - b.percentage_of_sku,
      multiple: 8,
    },
    render: (value: number) => value && <div>{value?.toFixed(2)}%</div>,
  },
  {
    title: 'No. of Supplier By Status',
    dataIndex: 'no_of_supplier_by_status',
    key: 'no_of_supplier_by_status',
    // width: 100,
    // fixed: 'left',
    children: [
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 112,
        sorter: {
          compare: (a, b) => a.total - b.total,
          multiple: 2,
        },
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Percentage of Total',
        dataIndex: 'percentage_of_total',
        key: 'percentage_of_total',
        width: 120,
        sorter: {
          compare: (a, b) => a.percentage_of_total - b.percentage_of_total,
          multiple: 9,
        },
        render: (value: number) => value && <div>{value?.toFixed(2)}%</div>,
      },
      {
        title: 'New',
        dataIndex: 'new',
        key: 'new',
        width: 112,
        sorter: {
          compare: (a, b) => a.new - b.new,
          multiple: 3,
        },
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Star',
        dataIndex: 'star',
        key: 'star',
        width: 112,
        sorter: {
          compare: (a, b) => a.star - b.star,
          multiple: 4,
        },
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Yellow',
        dataIndex: 'yellow',
        key: 'yellow',
        width: 112,
        sorter: {
          compare: (a, b) => a.yellow - b.yellow,
          multiple: 5,
        },
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Red',
        dataIndex: 'red',
        key: 'red',
        width: 112,
        sorter: {
          compare: (a, b) => a.red - b.red,
          multiple: 6,
        },
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Green',
        dataIndex: 'green',
        key: 'green',
        width: 112,
        sorter: {
          compare: (a, b) => a.green - b.green,
          multiple: 7,
        },
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      // {
      //   title: 'Deactive',
      //   dataIndex: 'deactive',
      //   key: 'deactive',
      //   width: 112,
      //   sorter: {
      //     compare: (a, b) => a.deactive - b.deactive,
      //     multiple: 8,
      //   },
      // },
      // {
      //   title: 'Clear',
      //   dataIndex: 'clear',
      //   key: 'clear',
      //   width: 112,
      //   sorter: {
      //     compare: (a, b) => a.clear - b.clear,
      //     multiple: 9,
      //   },
      // },
      // {
      //   title: 'Promotion',
      //   dataIndex: 'promotion',
      //   key: 'promotion',
      //   width: 112,
      //   sorter: {
      //     compare: (a, b) => a.promotion - b.promotion,
      //     multiple: 10,
      //   },
      // },
    ],
  },
]
export const sortbyOptions = [
  {
    label: 'Revenue',
    value: 'revenue',
  },
  {
    label: 'Profit',
    value: 'profit',
  },
  {
    label: 'Gross Margin',
    value: 'gross_margin',
  },
  {
    label: 'No. of Customer',
    value: 'no_of_customer',
  },
]
