import { Select, Skeleton, Tabs, Tooltip } from 'antd'
import axios from 'axios'
import TableHeader from 'Components/Table2.0/Components/Header'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { getPivotCateList, getProductPivotCateList } from 'Services/Analytics/product-analytics'
import CatePivotTemplate from '../../Components/CatePivotTemplate'
import { sortbyOptions } from './ChartRow_1.constants'
const { TabPane } = Tabs
const { Option } = Select
const ChartRow_4 = () => {
  const [view, setChangeView] = useState('list')
  const [sortby, setSortby] = useState<any>('revenue')
  const [sortByLabel, setSortByLabel] = useState('Revenue')
  const [keyword, setKeyword] = useState<string>('')
  const [searchList, setSearchList] = useState<{ key: string; title: string }[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [cateId, setCateId] = useState<any>('root')
  const [isSearch, setIsSearch] = useState<boolean>(false)
  let cancelToken
  const handleSearch = async (v) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    setLoading(true)
    try {
      setSearchList([])
      const resp = await getProductPivotCateList({
        keyword: v,
        cancelTokenSource: cancelToken,
      })
      setLoading(false)
      setSearchList(resp.data)
    } catch (err: any) {
      // message.error('Something went wrong')
    }
  }

  const debounceDropDown = useCallback(
    debounce((nextValue) => {
      handleSearch(nextValue)
      setIsSearch(false)
    }, 1000),
    []
  )
  useEffect(() => {
    isSearch && debounceDropDown(keyword)
  }, [isSearch])

  const options = searchList?.map((item) => {
    return <Option value={item?.key}>{item?.title}</Option>
  })
  const renderHeader = () => {
    return (
      <div className="flex mb-2 CatePivot-header">
        <Select
          allowClear
          defaultActiveFirstOption={false}
          onClear={() => setCateId('root')}
          style={{ width: 272, marginRight: 8 }}
          loading={loading}
          filterOption={false}
          notFoundContent={null}
          showSearch
          placeholder="Search"
          className="search-bar"
          onSearch={(e) => {
            setKeyword(e)
          }}
          onSelect={(e) => {
            setCateId(e)
          }}
          dropdownStyle={{ padding: 8 }}
          dropdownRender={(menu) => (
            <Skeleton loading={loading} active>
              {menu}
            </Skeleton>
          )}
          onInputKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              setIsSearch(true)
            } else if (e.key === ' ') {
              setIsSearch(true)
            }
          }}
        >
          {options}
        </Select>
        {/* <Select
          placeholder={`Sort by: ${sortByLabel} `}
          onSelect={(val) => {
            setSortby(val)
          }}
        >
          {sortbyOptions.map((item) => {
            return <Option value={item.value}>{item.label}</Option>
          })}
        </Select> */}
      </div>
    )
  }
  return (
    <div>
      <div className=" col-span-1 CatePivot">
        <Tooltip
          title="This static table won't be impacted by the selected time range and frequency"
          placement="top"
          overlayStyle={{ fontSize: '12px', backgroundColor: '#000000', width: '188px' }}
        >
          <div className="font-semibold text-lg mb-2" style={{ width: 'fit-content' }}>
            Trendview*
          </div>
        </Tooltip>
      </div>
      <div>
        <Tabs defaultActiveKey="1" tabBarExtraContent={renderHeader()}>
          <TabPane tab="Product" key="1">
            <CatePivotTemplate
              analytic_type="product_x_status"
              category_id={cateId}
              order_by={sortby}
            ></CatePivotTemplate>
          </TabPane>
          <TabPane tab="Customer" key="2">
            <CatePivotTemplate
              analytic_type="product_x_customer"
              category_id={cateId}
              order_by={sortby}
            ></CatePivotTemplate>
          </TabPane>
          <TabPane tab="Sale Channel" key="3">
            <CatePivotTemplate
              analytic_type="product_x_sale_channel"
              category_id={cateId}
              order_by={sortby}
            ></CatePivotTemplate>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default ChartRow_4
